.site-wrapper {
  height: 100%;
  background-image: url(../public/gasworks.png);
  background-repeat: no-repeat;
  background-position: bottom -50px right -65px;
  background-size: contain;
  display: flex;
}

.content-wrapper {
  width: auto;
  min-width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: rgb(251 251 251 / 75%);
  box-shadow: 3px 3px 4px 0px #74544f5e;
  h1 {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: rgb(119 54 36);
  }
  button {
    cursor: pointer;
  }
  .content {
    padding: 30px;
    font-weight: 600; 
  }
  .links {
    margin-top: 30px;
  }
}

.placeholder {
  height: 20px;
}

@media  (max-width: 640px) {
  .content-wrapper {
    width: 100%;
    justify-content: unset;
    padding: 30px 20px;
    background-color: rgb(251 251 251 / 65%);
    font-size: 1rem;
    h1 {
      margin-bottom: 30px;
    }
  }
}