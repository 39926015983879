/*
Index page style sheet file
*/
html {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
	font-family: "Trebuchet MS", Helvetica, sans-serif;
	font-size: 16px;
	margin: 0;
	width: 100%;
	height: 100%;
    background: linear-gradient(0deg, #a6a173, 4%, #f0f0f0);
}

#root {
	height: 100%;
}